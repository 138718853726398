<template>
	<div class="userInfo">
		<mallHeader />
		<div class="mall-userInfo container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">个人中心</a></li>
			</ul>
			<div class="my-money">
				<div class="my-money-top">
					<span>我的钱包</span>
					<i class="el-icon-view"></i>
				</div>
				<div class="my-money-content">
					<ul>
						<li>
							<div class="num"><a href="#">1</a></div>
							<div class="name">优惠卷</div>
							<div class="operate">领卷</div>
						</li>
						<li>
							<div class="num"><a href="#">5</a></div>
							<div class="name">优惠卷</div>
							<div class="operate">领卷</div>
						</li>
						<li>
							<div class="num"><a href="#">0.00</a></div>
							<div class="name">优惠卷</div>
							<div class="operate">领卷</div>
						</li>
						<li>
							<div class="num"><a href="#">0.00</a></div>
							<div class="name">优惠卷</div>
							<div class="operate">领卷</div>
						</li>
						<li>
							<div class="num"><a href="#">0.00</a></div>
							<div class="name">优惠卷</div>
							<div class="operate">领卷</div>
						</li>
					</ul>
				</div>
				<div class="head-portrait">
					<img src="@/assets/images/kq.jpg" alt="" />
					<div class="name">请叫我柯先生</div>
				</div>
			</div>
			<el-row>
				<el-col :span="16"
					><div class="grid-content bg-purple">
						<div class="my-order">
							<div class="my-order-top">
								<span>我的订单</span>
							</div>
							<div class="my-order-middle">
								<ul>
									<li>
										<img src="@/assets/images/icon/待付款.svg" alt="" /><span
											class="name"
											>待付款</span
										>
									</li>
									<li>
										<img src="@/assets/images/icon/待付款.svg" alt="" /><span
											class="name"
											>待收货</span
										>
									</li>
									<li>
										<img src="@/assets/images/icon/待付款.svg" alt="" /><span
											class="name"
											>待评价</span
										>
									</li>
									<li>
										<img src="@/assets/images/icon/待付款.svg" alt="" /><span
											class="name"
											>退款/售后</span
										>
									</li>
									<li>
										<img src="@/assets/images/icon/待付款.svg" alt="" /><span
											class="name"
											>全部订单</span
										>
									</li>
								</ul>
							</div>
							<div class="my-order-footer">
								<i class="el-icon-shopping-cart-full"></i>
								您买的东西太少了，这里都空空的，快去挑选合适的商品吧！
							</div>
						</div>
						<div class="product-items">
							<div class="fnPrev" @click="fnPrev()">
								<i class="el-icon-arrow-left"></i>
							</div>
							<div class="fnNext" @click="fnNext()">
								<i class="el-icon-arrow-right"></i>
							</div>
							<div
								class="product-item2"
								v-for="(item, index) in progressList"
								:key="index"
							>
								<a href="#"
									><img src="@/assets/images/h1.jpg" alt=""/>
									<img src="@/assets/images/h2.jpg" alt="" class="active"
								/></a>
								<div class="star">
									<ul>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/星星.svg" alt="" />
										</li>
										<li>
											<img src="@/assets/images/icon/灰星星.svg" alt="" />
										</li>
									</ul>
								</div>
								<h4>
									<a
										href="product.html"
										title="Pastrami bacon"
										target="_self"
										>{{ item.type }}</a
									>
								</h4>
								<div class="money">
									<span class="now-money">$55.00 </span>
									<span class="old-money">$76.00 </span>
								</div>
								<span class="lable">-9%</span>
								<!-- <span class="eye"
												><img src="@/assets/images/icon/眼睛.svg" alt=""
											/></span> -->
								<span class="refresh el-icon-refresh"></span>
								<span class="love el-icon-star-off"></span>
								<span class="addToCart">添加购物车</span>
							</div>
						</div>
					</div></el-col
				>
				<el-col :span="8"
					><div class="grid-content bg-purple-light">
						<div class="my-attention">
							<div class="my-attention-top">
								<span>我的关注</span>
							</div>
							<div class="my-attention-footer">
								<ul>
									<li>
										<span class="num">0</span>
										<span class="name">商品关注</span>
									</li>
									<li>
										<span class="num">0</span>
										<span class="name">店铺关注</span>
									</li>
								</ul>
							</div>
						</div>
						<div class="life-service">
							<div class="life-service-top">
								<span>生活服务</span>
							</div>
							<div class="life-service-footer">
								<el-carousel arrow="always" height="250px" :autoplay="false">
									<el-carousel-item>
										<ul class="service-list">
											<li>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
											</li>
											<li>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
											</li>
										</ul>
									</el-carousel-item>
									<el-carousel-item>
										<ul class="service-list">
											<li>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
											</li>
											<li>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
												<div class="service-item">
													<i class="icon iconfont icon-dating"></i>
													<span>游戏</span>
												</div>
											</li>
										</ul>
									</el-carousel-item>
								</el-carousel>
							</div>
						</div>
					</div></el-col
				>
			</el-row>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
export default {
	name: 'product',
	components: {
		mallHeader,
	},
	data() {
		return {
			progressList: [
				{ type: 1 },
				{ type: 2 },
				{ type: 3 },
				{ type: 4 },
				{ type: 5 },
			],
		}
	},
	methods: {
		fnPrev() {
			let prev = [...this.progressList]
			prev.unshift(prev.pop())
			this.progressList = prev
			// console.log(this.progressList);
			// this.fnScrollWidth('reduce')
		},
		fnNext() {
			let next = [...this.progressList]
			next.push(next.shift())
			this.progressList = next
			// console.log(this.progressList);
		},
	},
}
</script>

<style lang="scss" scoped>
.userInfo {
	color: #666;
	background-color: #f0f3ef;
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-userInfo {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.my-money {
			// height: 280px;
			// background: yellow;
			margin: 30px 20px 30px 250px;
			background-color: #fff;
			position: relative;
			.my-money-top {
				height: 50px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #f0f3ef;
				padding: 0 20px;
				// background: blue;
				i {
					cursor: pointer;
					color: #ccc;
				}
				i:hover {
					color: #005ea6;
				}
			}
			.my-money-content {
				ul {
					display: flex;
					padding: 30px 20px;
					justify-content: space-between;
					li {
						.num {
							font-weight: 700;
							color: #333;
							font-size: 22px;
						}
						.name {
							margin: 20px 0;
							font-size: 14px;
						}
						.operate {
							font-size: 14px;
							color: #005ea6;
						}
					}
				}
			}
			.head-portrait {
				width: 250px;
				height: 255px;
				background-color: #005ea6;
				position: absolute;
				top: -15px;
				left: -250px;
				box-shadow: 2px 2px 10px rgb(0 0 0 / 30%);
				text-align: center;
				img {
					border-radius: 100px;
					border: 5px solid #fff;
					margin-top: 20px;
				}
				.name {
					font: 700 18px/20px Arial;
					color: #fff;
					margin-top: 20px;
				}
			}
		}
		.my-order {
			background-color: #fff;
			.my-order-top {
				height: 50px;
				border-bottom: 1px solid #f0f3ef;
				line-height: 50px;
				padding: 0 20px;
			}
			.my-order-middle {
				height: 120px;
				ul {
					display: flex;
					justify-content: space-around;
					align-items: center;
					margin: 20px 0;
					li {
						display: flex;
						flex-direction: column;
						align-items: center;
						height: 90px;
						width: 120px;
						transition: all 0.4s ease;
						img {
							width: 48px;
							height: 48px;
						}
						.name {
							font-size: 14px;
							margin-top: 10px;
						}
					}
					li:hover {
						box-shadow: 0 0 16px 0 rgb(0 0 0 / 10%);
						border-radius: 5px;
					}
				}
			}
			.my-order-footer {
				height: 220px;
				text-align: center;
				line-height: 220px;
				i {
					font-size: 70px;
					color: #999;
					background-color: #fff;
				}
			}
		}
		.my-attention {
			background-color: #fff;
			margin: 0 20px;
			.my-attention-top {
				height: 50px;
				border-bottom: 1px solid #f0f3ef;
				line-height: 50px;
				padding: 0 20px;
			}
			.my-attention-footer {
				ul {
					display: flex;
					justify-content: space-around;
					li {
						display: flex;
						flex-direction: column;
						margin: 20px;
						.name {
							margin-top: 10px;
							font-size: 14px;
						}
					}
					li:hover .num {
						color: #005ea6;
					}
				}
			}
		}
		.life-service {
			background-color: #fff;
			margin: 0 20px;
			.life-service-top {
				height: 50px;
				border-bottom: 1px solid #f0f3ef;
				line-height: 50px;
				padding: 0 20px;
			}
			.life-service-footer {
				.service-list {
					padding: 0;
					li {
						flex: 1;
						display: flex;
						justify-content: space-around;
						align-items: center;
						.service-item {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							height: 125px;
							i {
								font-size: 40px;
							}
						}
						.service-item:hover {
							color: #005ea6;
						}
					}
				}
			}
		}
		.product-items {
			display: flex;
			margin-bottom: 20px;
			margin-top: 20px;
			position: relative;
			background-color: #fff;
			overflow: hidden;
			.product-item2:hover .active {
				opacity: 0;
				transition: all 0.5s;
			}
			.product-item2:hover .eye {
				opacity: 1;
				animation: change2 0.5s;
			}
			.product-item2:hover .love {
				opacity: 1;
				animation: animLoadedHeader2 0.5s;
			}
			.product-item2:hover .refresh {
				opacity: 1;
				animation: animLoadedHeader1 0.5s;
			}
			.product-item2:hover .addToCart {
				opacity: 1;
				animation: animLoadedHeader3 0.5s;
			}
			.product-item2:hover .star {
				opacity: 0;
			}
			.product-item2:hover h4 {
				opacity: 0;
			}
			.product-item2 {
				position: relative;
				width: 190px;
				text-align: center;
				margin: 0 5px;
				a {
					img {
						position: relative;
						width: 190px;
						height: 190px;
					}
					.active {
						// opacity: 0;
						position: absolute;
						top: 0;
						right: 0;
					}
				}
				.star {
					ul {
						display: flex;
						padding-left: 62px;
						margin-bottom: 5px;
						li {
							list-style: none;
							img {
								width: 12px;
								height: 12px;
							}
						}
					}
				}
				h4 {
					font-size: 13px;
					color: #333;
				}
				.money {
					margin-bottom: 10px;
					margin-top: 10px;
					.now-money {
						color: #005ea6;
						font-size: 16px;
						font-weight: 600;
					}
					.old-money {
						text-decoration: line-through;
						color: #aaa;
					}
				}
				.top-right {
					flex: 1;
					// background-color: #005ea6;
					display: flex;
					// justify-content: center;
					margin-left: 88px;
					align-items: center;
					.dot1 {
						width: 30px;
						height: 8px;
						border-radius: 4px;
						background-color: #005ea6;
						margin-right: 5px;
					}
					.dot2 {
						width: 8px;
						height: 8px;
						margin: 0 5px;
						background-color: #222;
						border-radius: 8px;
					}
				}
				.lable {
					width: 38px;
					height: 38px;
					border-radius: 38px;
					background-color: #ffd839;
					text-align: center;
					line-height: 38px;
					position: absolute;
					top: 0;
					right: 0;
				}
				.eye {
					width: 30px;
					height: 30px;
					background-color: #005ea6;
					position: absolute;
					top: 115px;
					right: 115px;
					text-align: center;
					line-height: 30px;
					border-radius: 30px;
					transform-origin: center;
					opacity: 0;
				}
				.eye:hover {
					background-color: #ff5e00;
				}
				.refresh {
					width: 30px;
					height: 30px;
					position: absolute;
					border: 2px solid #005ea6;
					top: 205px;
					right: 15px;
					text-align: center;
					border-radius: 30px;
					line-height: 25px;
					color: #005ea6;
					opacity: 0;
				}
				.refresh:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.love {
					width: 30px;
					height: 30px;
					position: absolute;
					border: 2px solid #005ea6;
					top: 205px;
					right: 50px;
					text-align: center;
					line-height: 25px;
					border-radius: 30px;
					color: #005ea6;
					opacity: 0;
				}
				.love:hover {
					background-color: #005ea6;
					color: #fff;
				}
				.addToCart {
					height: 30px;
					width: 100px;
					position: absolute;
					top: 205px;
					right: 85px;
					text-align: center;
					background-color: #005ea6;
					color: #fff;
					line-height: 30px;
					border-radius: 15px;
					opacity: 0;
				}
				.addToCart:hover {
					background-color: #ff5e00;
				}
			}
			.fnPrev {
				width: 33px;
				height: 33px;
				border-radius: 33px;
				text-align: center;
				line-height: 33px;
				z-index: 999;
				position: absolute;
				top: 125px;
				left: 0;
				box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
				background-color: #fff;
				opacity: 0;
			}
			.fnPrev:hover {
				background-color: #005ea6;
				color: #fff;
			}
			.fnNext {
				width: 33px;
				height: 33px;
				border-radius: 33px;
				text-align: center;
				line-height: 33px;
				z-index: 999;
				position: absolute;
				top: 125px;
				right: 0;
				box-shadow: 2px 0px 3px 0px rgb(155 155 155 / 75%);
				background-color: #fff;
				opacity: 0;
			}
			.fnNext:hover {
				background-color: #005ea6;
				color: #fff;
			}
		}
		.product-items:hover .fnNext {
			opacity: 1;
			animation: change2 0.5s;
		}
		.product-items:hover .fnPrev {
			opacity: 1;
			animation: change2 0.5s;
		}
	}
	@keyframes change2 {
		from {
			transform: scale(0.5);
			opacity: 0;
		}
		to {
			transform: scale(1);
			opacity: 1;
		}
	}
	@keyframes animLoadedHeader1 {
		to {
			opacity: 1;
			transform: translate3d(0, 0px, 0);
		}
		from {
			opacity: 0;
			transform: translate3d(0, -40px, 0);
		}
	}
	@keyframes animLoadedHeader2 {
		to {
			opacity: 1;
			transform: translate3d(0, 0px, 0);
		}
		from {
			opacity: 0;
			transform: translate3d(0, -45px, 0);
		}
	}
	@keyframes animLoadedHeader3 {
		to {
			opacity: 1;
			transform: translate3d(0, 0px, 0);
		}
		from {
			opacity: 0;
			transform: translate3d(0, -50px, 0);
		}
	}
}
</style>
